$backgroundImage: '../../assets/images/nft-input-background.jpg';

.form-wrapper {
  position: relative;
  background-image: url(#{$backgroundImage});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;

  .form-container {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 35px 0 50px 0;
    margin: auto 0;

    @media (min-width: 768px) {
      max-width: 678px;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 20vh;
    }
    @media (max-width: 767px) {
      justify-content: center;
    }

    h1 {
      font-size: 36px;
    }

    p {
      font-weight: 300;
      margin: 30px 0 20px 0;
      line-height: 28px;
      font-size: 18px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
      padding: 0 60px;
    }

    .form-input {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .input {
      border-radius: 15px;
      padding: 8px 16px;
      margin-bottom: 20px;
      border: none;
      background-color: #e5e5e5;
      outline: none;
      font-size: 14px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.error {
        border: 2px solid red;
        color: red;

        ::placeholder {
          color: red;
        }
      }
    }
  }
  .radio-btn-container {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    align-self: center;
    align-items: center;

    .radio-container {
      display: flex;
      align-items: baseline;
    }
  }
}

/* CUSTOMIZING RADIO BUTTONS */

.css-1rggdzb {
  background: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  color: #c4c4c4 !important;
  box-shadow: none !important;

  &[data-checked] {
    background: black !important;
    border-color: #c4c4c4 !important;
    color: black !important;
    box-shadow: none !important;
  }
}
