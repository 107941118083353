$errorDesktop: '../../assets/images/SFONDO-NFT-NOT-FOUND.png';
$errorMobile: '../../assets/images/SFONDO-NFT-NOT-FOUND-MOBILE.png';

.error-page-container {
  display: flex;
  background-image: url(#{$errorDesktop});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;

  @media (max-width: 767px) {
    background-image: url(#{$errorMobile});
  }

  .back-btn {
    /* position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%); */
    padding: 0.75rem 1rem;
    color: white;
    background-color: transparent;
    font-weight: bolder;
    border-radius: 20px;
    border: 2px solid white;
    cursor: pointer;
    width: fit-content;
    text-align: center;
    z-index: 1;
    margin: auto auto 20vh auto;
  }
}
