.header {
  position: fixed;
  top: 0;
  z-index: 900;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  box-sizing: border-box;

  @media (max-width: 1023px) {
    margin-top: 20px;
  }

  .logo {
    margin-right: auto;
  }

  .nav-item {
    text-transform: uppercase;
    color: $color-white;
  }

  .navigation-desktop {
    @media (max-width: 1023px) {
      display: none;
    }
    .nav-item {
      font-size: 14px;
      margin-right: 2rem;
      font-weight: 300;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .navigation-mobile {
    @media (min-width: 1023px) {
      display: none;
    }

    background-color: $color-black;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 60px 0;
    box-sizing: border-box;

    .menu-nav {
      margin: 90px 0 auto;
      display: flex;
      flex-direction: column;
      .nav-item {
        padding: 20px;
        font-size: 24px;
        font-weight: 600;

        &.disabled {
          text-decoration: line-through;
        }
      }
    }

    .social-nav {
      margin-top: auto;
    }

    .close-button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid $color-white;
      cursor: pointer;
      margin-top: 75px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.burger-menu {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 992;

  @media (min-width: 1024px) {
    display: none;
  }

  &.open {
    @media (min-width: 1024px) {
      .dish .burger-item.bread-bottom {
        width: 100%;
      }
    }
    @media (max-width: 1023px) {
      .dish .burger-item.bread-top {
        transform: rotate(45deg);
        top: 50%;
      }
      .dish .burger-item.meat {
        width: 0;
      }
      .dish .burger-item.bread-bottom {
        width: 100%;
        transform: rotate(-45deg);
        top: 50%;
      }
    }
  }

  .dish {
    width: 32px;
    height: 20px;
    position: relative;

    .burger-item {
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background-color: $color-white;
      position: absolute;

      &.bread-top {
        top: 0;
      }
      &.meat {
        top: 50%;
        transform: translate(0, -50%);
      }
      &.bread-bottom {
        bottom: 0;
      }
    }
  }
}
