@import 'assets/styles/fonts.scss';
@import 'assets/styles/variables.scss';
@import 'assets/styles/header.scss';

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

#root {
  background: black;
  overflow: hidden;
}

body {
  background-color: black;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}

.container {
  width: 100%;

  padding: 0 100px;
  box-sizing: border-box;

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
}

.social {
  display: flex;

  .social-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
  }
}

.copy {
  color: $color-white;
  white-space: nowrap;
  font-size: 14px;
}

.app-wrapper {
  position: relative;
  left: 100%;
  z-index: 2;
  visibility: hidden;
  overflow: hidden;

  &.visible {
    left: 0 !important;
    transition: left 0.5s;
    visibility: visible;
  }

  &.isFullscreen {
    .header {
      display: none;
    }
    .footer {
      display: none;
    }
  }
}

.app-loader {
  z-index: 1;
  width: 256px;
  height: 256px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 200px;
    height: 200px;
  }

  &.hidden {
    .loader {
      opacity: 0;
      scale: 0;
      transition: all 1s;
    }
  }

  .loader {
    width: 100%;
    height: 100%;
    .logo-stroke {
      transform: rotate(-90deg);
    }
    .logo-text {
      position: absolute;
      left: 0;
    }

    svg {
      width: 100%;
      height: auto;
    }

    .circle-animation {
      /* calculate using: (2 * PI * R) */
      stroke-dasharray: 227;
      stroke-dashoffset: 227;
      stroke: white;
      stroke-width: 1;
      animation-iteration-count: 1;
      animation-name: rotate;
      animation-duration: 2.5s;
      animation-timing-function: linear;
    }

    @keyframes rotate {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
}

.action-btn {
  margin-top: 36px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 20px;
  border: 2px solid white;
  color: white;
  padding: 8px 22px;
  text-transform: uppercase;
  background: black;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-top: 56px;
    width: 211px;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
    &:hover {
      background: black;
      font-weight: 600;
    }
  }
}

.button-hover {
  &:hover {
    background: linear-gradient(to right, #cf47ff, #225cb4);
    font-weight: 400;
  }
}

a.disabled {
  pointer-events: none;
}
