.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    display: none;
  }

  .footer-hr {
    background-color: white;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    margin: 0 2rem 0 1.3rem;
  }
}
