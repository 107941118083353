$backgroundImage: '../../assets/images/landing-background.jpg';

.landing-wrapper {
  position: relative;
  background-image: url(#{$backgroundImage});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;

  @media (max-width: 1023px) {
    background-position: 65% center;
  }
}

.hero-container {
  margin: auto 0;
  color: white;

  h2 {
    font-weight: 300;
    font-size: 3.75rem;
  }
  h1 {
    font-size: 5rem;

    @media (max-width: 1023px) {
      font-size: 4rem;
    }
  }

  .cta {
    text-transform: uppercase;
    font-style: italic;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

.scroll-swipe {
  width: 30px;
  width: 30px;
  margin-left: 20px;

  svg {
    animation-iteration-count: infinite;
    animation-name: swipe;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

  @keyframes swipe {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(10px, 0);
    }
    50% {
      transform: translate(10px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.scroll-icon {
  width: 20px;
  height: 38px;
  border-radius: 10px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  margin-left: 20px;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid white;
    margin-top: 5px;
    animation-iteration-count: infinite;
    animation-name: dot;
    animation-duration: 2s;
    animation-timing-function: linear;
  }

  @keyframes dot {
    50% {
      margin-top: 25px;
      opacity: 0;
    }
    100% {
      margin-top: 25px;
      opacity: 0;
    }
  }
}
