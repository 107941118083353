.loader {
  &.xs {
    width: 20px;
    height: 20px;
  }

  &.sm {
    width: 30px;
    height: 30px;
  }

  &.md {
    width: 40px;
    height: 40px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
