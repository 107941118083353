.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nft-container {
  display: flex;
  color: white;
  background-color: black;

  @media (min-width: 1024px) {
    padding-top: 130px;
    padding-bottom: 110px;
    height: 100vh;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 0 !important;
    padding-top: 120px !important;
    max-width: 768px;
    overflow-y: auto;
  }

  .nft-detail {
    flex: 0 0 500px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (max-width: 1399px) {
      flex: 0 0 400px;
    }
    @media (max-width: 1279px) {
      flex: 0 0 350px;
    }
    @media (max-width: 1023px) {
      flex: 0 0 auto;
      order: 2;
      padding: 20px;
    }

    .detail-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      .detail-menu-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .detail-button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 30px;
          height: 30px;
        }
        .detail-menu-content {
          width: 100%;
          width: 280px;
          border: 1px solid white;
          background: black;
          padding: 0.5rem;
          box-sizing: border-box;

          @media (min-width: 1023px) {
            position: absolute;
            top: 30px;
            right: 0;
            z-index: 1;
          }

          @media (max-width: 479px) {
            width: calc(100vw - 40px);
          }

          .detail-menu-item {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            border-top: 1px solid white;
            font-weight: 600;

            .inner {
              padding: 0 0.75rem;
              height: 45px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;

              span {
                font-size: 13px;
                font-weight: 400;
              }
            }

            .withFlag {
              cursor: pointer;
              flex-direction: row;
              align-items: center;

              .flag {
                display: flex;
                align-items: center;

                &.expanded {
                  transform: rotate(180deg);
                }
              }
            }

            &:first-of-type {
              border: none;
            }
          }
        }

        .flag-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1rem;

          .flag-item {
            display: flex;
            align-items: flex-end;
            font-size: 14px;

            .checkbox {
              width: 20px;
              height: 20px;
              border: 1px solid white;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    font-size: 18px;

    b {
      font-weight: 600;
    }

    .title {
      margin: 20px 0;
      @media (max-width: 1023px) {
        margin: 20px 0 10px;
      }
    }
    .description {
      margin: 60px 0 40px;
      @media (min-width: 1024px) {
        overflow: auto;
      }
      @media (max-width: 1023px) {
        margin: 10px 0 0 0;
      }

      .description-container {
        @media (max-width: 1023px) {
          max-height: 130px;
          overflow: hidden;
          &.expanded {
            max-height: inherit;
          }
        }

        p {
          font-size: 14px;
          @media (max-width: 1023px) {
            margin-top: 10px;
            line-height: 1.8;
          }
        }
      }

      .read-more {
        font-size: 14px;
        text-decoration: underline;
        text-align: right;
        margin-top: 20px;
        cursor: pointer;

        @media (min-width: 1024px) {
          display: none;
        }
      }
    }

    .metadata {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      margin-top: auto;

      @media (max-width: 1279px) {
        grid-gap: 20px;
      }
      @media (max-width: 1023px) {
        margin-top: 100px;
      }

      @media (max-width: 479px) {
        grid-template-columns: 1fr;
      }

      .metadata-item {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        border-radius: 20px;
        border: 2px solid white;
        color: white;
        padding: 10px 0;

        @media (max-width: 1279px) {
          font-size: 13px;
        }
      }
    }
  }

  .nft-media {
    width: 100%;
    height: 100%;
    position: relative;
    @media (max-width: 1023px) {
      order: 1;
      height: auto;
    }

    .image-container {
      width: 100%;
      height: 100%;
      img,
      video {
        width: 100%;
        display: block;
        @media (min-width: 1024px) {
          height: 100%;
          object-fit: contain;
        }
        @media (max-width: 1023px) {
          height: auto;
        }
      }
    }

    .media-actions {
      position: absolute;
      @media (min-width: 1024px) {
        width: 24px;
        right: -62px;
        top: 50%;
        transform: translate(0, -50);
      }
      @media (max-width: 1023px) {
        display: flex;
        bottom: -50px;
        left: 20px;
      }

      .media-actions-item {
        cursor: pointer;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;

        @media (max-width: 1023px) {
          margin-bottom: 0;
          margin-right: 1rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        svg {
          width: 24px;
          height: 24px;
        }

        .copied {
          white-space: nowrap;
          font-size: 13px;
          position: absolute;
          top: 100%;
          @media (max-width: 1023px) {
            top: 25%;
            left: 100%;
          }
        }
      }
    }

    .certified {
      position: absolute;
      width: 77px;
      height: 77px;
      right: -87px;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      padding: 0.5rem;

      @media (max-width: 1023px) {
        right: 20px;
        top: 20px;
      }
    }
  }
}

.fullscreen-media {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;

  .media {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .media-actions {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    width: 80px;
    height: 50%;
    position: absolute;
    right: 0;
    top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    .media-actions-item {
      cursor: pointer;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      svg {
        width: 40px;
      }
    }
  }
}
