@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat'), url('../../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
}
